(function() {
  const dataCountryResponses = {
    'data': {
      'countries': [
        {
          'id': '01',
          'countryCode': 'AU',
          'countryName': 'Australia',
          'phoneCode': '61'
        },
        {
          'id': '02',
          'countryCode': 'SIN',
          'countryName': 'Singapore',
          'phoneCode': '65'
        },
        {
          'id': '03',
          'countryCode': 'ENG',
          'countryName': 'England',
          'phoneCode': '67'
        }
      ],
      'states': [
        {
          'id': '01',
          'countryCode': 'AU',
          'stateCode': 'AU_STATE_1',
          'stateName': 'AU STATE 1'
        },
        {
          'id': '02',
          'countryCode': 'AU',
          'stateCode': 'AU_STATE_2',
          'stateName': 'AU STATE 2'
        },
        {
          'id': '03',
          'countryCode': 'SIN',
          'stateCode': 'SIN_STATE_1',
          'stateName': 'SIN STATE 1'
        },
        {
          'id': '04',
          'countryCode': 'SIN',
          'stateCode': 'SIN_STATE_2',
          'stateName': 'SIN STATE 2'
        },
      ],
      'cities': [
        {
          'countryCode': 'AU',
          'stateCode': 'AU_STATE_1',
          'id': '01',
          'cityCode': 'SYD',
          'cityName': 'AU CITY 1'
        },
        {
          'countryCode': 'AU',
          'stateCode': 'AU_STATE_1',
          'id': '02',
          'cityCode': 'MEL',
          'cityName': 'AU CITY 2'
        },
        {
          'countryCode': 'AU',
          'stateCode': 'AU_STATE_2',
          'id': '03',
          'cityCode': 'BRI',
          'cityName': 'AU CITY 3'
        },
        {
          'countryCode': 'SIN',
          'stateCode': 'SIN_STATE_1',
          'id': '04',
          'cityCode': 'SIN1',
          'cityName': 'SIN CITY 1'
        },
        {
          'countryCode': 'SIN',
          'stateCode': 'SIN_STATE_1',
          'id': '05',
          'cityCode': 'SIN2',
          'cityName': 'SIN CITY 2'
        },
        {
          'countryCode': 'SIN',
          'stateCode': 'SIN_STATE_2',
          'id': '05',
          'cityCode': 'SIN2',
          'cityName': 'SIN CITY 3'
        },
        {
          'countryCode': 'ENG',
          'stateCode': 'ENG_STATE_1',
          'id': '06',
          'cityCode': 'ENG1',
          'cityName': 'ENG CITY 1'
        },
        {
          'countryCode': 'ENG',
          'stateCode': 'ENG_STATE_2',
          'id': '07',
          'cityCode': 'ENG2',
          'cityName': 'ENG CITY 2'
        }
      ],
      'errorCode': '',
      'errorMessage': '',
    }
  };

  function getCountriesStatesCities() {
    return dataCountryResponses;
  }

  window.countryDataService = {
    getCountriesStatesCities,
  };
})(window);
